body {
	overflow-y: scroll;
}

#section-home::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 55px;
	background: linear-gradient(to top, #1c392d, transparent);
	z-index: 9;
}

#section-nft::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 25px;
	background: linear-gradient(to top, #1c392d, transparent);
	z-index: 9;
}

#section-roadmap {
	background: linear-gradient(
		to bottom,
		#09291c,
		#334b22,
		#334b22,
		#584734,
		#584734,
		#19140c
	);
}

.roadmap-phase:hover {
	transition: all 0.3s ease-in-out;
	transform: scale(1.1);
}

#partners_svg__partner-adamant:hover,
#partners_svg__partner-bitskwela:hover,
#bgm-svg:hover,
#home-foreground_svg__fg-signage:hover {
	filter: brightness(120%);
	cursor: pointer;
}

.overlay-button:hover {
	fill: white;
}

#loading {
	z-index: 99999999999;
	overflow: hidden;
}

::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #09191e;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #fff447;
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #ccc338;
}
