@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "AnimeAceBold";
	src: url("/fonts/AnimeAceBold.ttf");
	font-style: normal;
	font-weight: 800;
	font-display: swap;
}

@font-face {
	font-family: "AnimeAce";
	src: url("/fonts/animeace2_reg.otf");
	font-style: normal;
	font-weight: 800;
	font-display: swap;
}

@font-face {
	font-family: "GlutenBold";
	src: url("/fonts/Gluten-Bold.otf");
	font-style: normal;
	font-weight: 800;
	font-display: swap;
}

@font-face {
	font-family: "GlutenRegular";
	src: url("/fonts/Gluten-Regular.otf");
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "JosefinSansBold";
	src: url("/fonts/JosefinSans-Bold.ttf");
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "JosefinSansRegular";
	src: url("/fonts/JosefinSans-Regular.ttf");
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "MyGrandpasFarm";
	src: url("/fonts/MyGrandpasFarm.ttf");
	font-style: normal;
	font-display: swap;
}

/**
* Reset
**/

html,
body {
	padding: 0;
	margin: 0;
	background-image: linear-gradient(#63c9fd, #d5e5ed);
	overflow-x: hidden;
}

/**
* Floating text Typography + box-sizing
**/
* {
	box-sizing: border-box;
	font-family: "Josefin Sans", sans-serif;
	/* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
		Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
	/* font-family: "JetBrains Mono", monospace; */
}

/**
* Headline typography
**/
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Gluten", cursive;
	font-weight: 600;
}

/**
	* Anchors
**/
a {
	color: inherit;
	text-decoration: none;
}

.navbar-responsive {
	z-index: 9999999999;
	background-image: url(/images/navwood.svg);
}

@media screen and (max-width: 767px) {
	.navbar-responsive {
		z-index: 9999999999;
		background-image: none;
		position: fixed;
	}

	.hamburger {
		z-index: 9999999999999;
	}
}

.sidebar {
	position: fixed;
	top: -100%;
	animation: down 1s normal forwards;
}

@keyframes down {
	0% {
		top: -100%;
	}
	100% {
		top: 0;
	}
}

@media screen and (max-width: 640px) {
	.sectiong-bg {
		height: 320vh;
	}
}

/* 
@media screen and (max-width:1750px){
	.gallery-layout{
		position: absolute;
		top: 10%;
		left: 50%;
		transform: translate(-50%, 10%);
	}

	#nft-farmers-top{
		padding-right: 0;
	}

	#nft-farmers-bottom{
		padding-right: 0;
	}
}


@media screen and (min-width:640px){
	.gallery-layout{
		position: absolute;
		top: 8%;
		left: 50%;
		transform: translate(-50%, 5%);
	}
	
}

@media screen and (max-width:640px){
	.gallery-layout{
		position: absolute;
		top: 5%;
		left: 50%;
		transform: translate(-50%, 5%);
	}
	
}

@media screen and (max-width:375px){
	.gallery-layout{
		position: absolute;
		top: 0%;
		left: 50%;
		transform: translate(-50%, 5%);
	}
	
} */

.content-wrapper {
	position: absolute;
	width: auto;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

#pig-racing_svg__g1 {
	opacity: 0;
}
#pig-racing_svg__g2 {
	opacity: 0;
}
#pig-racing_svg__g3 {
	opacity: 0;
}
#pig-racing_svg__go {
	opacity: 0;
}

/* prepare the selectors to add a stroke to */

/* prepare the selectors to add a stroke to */
